// TODO [mfa] each component should require the style sheets it needs
// app:style
require('@mdi/font/css/materialdesignicons.css')
require('normalize.css/normalize.css')
require('ng-sortable/dist/ng-sortable.css')

require('styles/helpers/am-flex.styl')
require('styles/helpers/borders.styl')
require('styles/helpers/buttons.styl')
require('styles/helpers/custom-checkbox.styl')
require('styles/helpers/helpers.styl')
require('styles/helpers/inputs.styl')
require('styles/helpers/spacers.styl')
require('styles/helpers/position.styl')
require('styles/helpers/text.styl')
require('styles/custom-pagination.styl')

require('styles/reset.css')
require('styles/main.css')
require('styles/styles.css')

require('styles/info.css')
require('styles/support.styl')

require('styles/modal_window.css')
require('styles/signature-field.css')
require('styles/splash-loader.styl')
require('styles/spinner.styl')

require('styles/sidebar.styl')
require('styles/header.styl')

require('styles/list_project.styl')
require('styles/showImage.css')

require('styles/unsupported-browsers-dialog.css')

require('styles/projects/auth/sign-in.css')
require('styles/projects/auth/privacy_summary.styl')
require('styles/projects/auth/activation-account.styl')
require('styles/projects/auth/activate-account-reminder.styl')
require('styles/projects/auth/blocker.styl')

require('styles/projects/time-tracking/activity-report.styl')
require('styles/projects/time-tracking/ar-overview.styl')
require('styles/projects/time-tracking/managing-activity-reports.styl')
require('styles/projects/time-tracking/tt-activity-report.styl')
require('styles/projects/time-tracking/tt-ar-preselected.styl')
require('styles/projects/time-tracking/tt-date-input.styl')
require('styles/projects/time-tracking/tt-notes.styl')
require('styles/projects/time-tracking/tt-popup.styl')
require('styles/projects/time-tracking/tt-report.styl')
require('styles/projects/time-tracking/tt-report-list.styl')

require('styles/projects/mileage-money/kilometergeld.styl')
require('styles/projects/mileage-money/mileage-money-popup.styl')

require('styles/projects/photo-document.styl')
require('styles/projects/ebs-item.styl')
require('styles/projects/refer-a-friend.styl')
require('styles/projects/employee-benefits.styl')
require('styles/projects/salary-reports.styl')
require('styles/projects/tutorial.styl')

require('styles/language-picker.styl')
require('styles/notification-component.styl')
require('styles/camera-ux.styl')

// AngularJS app
import './app-ng';

require('angular-translate')
require('angular-translate-loader-static-files')

require('/views/base_route.html')

import './services/auth';
import './services/backbutton';
import './services/localization';
import './services/db_system';
import './services/application_rating';
import './services/api';
import './services/notification';
import './services/file_system';
import './services/request_permissions';
import './services/push-notification';
import './services/reduced';
import './services/sidebar';
import './services/env';
import './services/err_handler';
import './services/helpers';
import './services/sync';
import './services/time-tracking-validation';
import './services/expired-jobs';
import './services/holidays';
import './services/releases';
import './services/pn-list';
import './services/mileage-money';

import './factories/app';
import './factories/config';
import './factories/db';
import './factories/err';
import './factories/user';
import './factories/splash-loader';
import './factories/loader';
import './factories/assignment';
import './factories/activity-report';
import './factories/time-tracking';
import './factories/overlay';
import './factories/unsupported_browsers_dialog';
import './factories/releases';
import './factories/pn-list';
import './factories/validation-error';
import './factories/vacation-request';
import './factories/mileage-money';

import './components/banner/banner.component';

// AngularJS controllers that are moved into TS
import './controllers/global';
import './controllers/info';
import './controllers/debug-screen';
import './controllers/list_projects';
import './controllers/sign-in';

require('./configs/run.es6')
require('./configs/routes.es6')
require('./configs/translate')
require('./configs/http.es6')
require('./configs/sanitize_whitelist')

// controlers:js
require('./controllers/header')
require('./controllers/sidebar')
require('./controllers/privacy_summary')
require('./controllers/restore-password')
require('./controllers/licenses')
require('./controllers/base-route')

// components:js
require('./components/time-trackings/ar-root/ar-root')
require('./components/time-trackings/job-select/job-select')
require('./components/time-trackings/report-status/report-status')
require('./components/time-trackings/tt-notes/tt-notes')
require('./components/time-trackings/tt-customer-email/tt-customer-email')
require('./components/small-popup/small-popup')
require('./components/select-popup/select-popup')
require('./components/time-trackings/time-tracking/job-confirm')
require('./components/time-trackings/daily-report/daily-report')
require('./components/time-trackings/tt-pauses-list/tt-pauses-list')
require('./components/time-trackings/tt-pauses-list/tt-pause')
require('./components/time-trackings/tt-date-input/tt-date-input')
require('./components/time-trackings/activity-report-preselected/activity-report-preselected')
require('./components/time-trackings/activity-report-new/activity-report')
require('./components/time-trackings/activity-report/activity-report')
require('./components/time-trackings/daily-report-list/daily-report-list')
require('./components/activity-reports-overview/activity-reports-overview')

require('./components/activation-account/aa-link')
require('./components/activation-account/aa-send-email')
require('./components/activation-account/aa-wrapper-page')
require('./components/activation-account/aa-enter-code')
require('./components/activation-account/aa-enter-password')
require('./components/activation-account/aa-successfully')
require('./components/activation-reminder-popup/activate-account-reminder')

require('./components/dot-navigation/dot-navigation')

require('./components/time-trackings/ar-by-photo-item/ar-by-photo-item')
require('./components/time-trackings/activity-report-phototn/ar-phototn')

require('./components/time-trackings/photo-document/root-items/pd-root-items')
require('./components/time-trackings/photo-document/pd-new/pd-new')
require('./components/time-trackings/photo-document/pd-list/pd-list')
require('./components/time-trackings/photo-document/pd-list/pd-list-item/pd-list-item')
require('./components/time-trackings/photo-document/pd-list/pd-list-item-details/pd-list-item-details')

require('./components/build-version/build-version')
require('./components/notification/notification')
require('./components/kilometergeld/kilometergeld')
require('./components/kilometergeld/mileage-money-popup/mileage-money-popup')

require('./components/tutorials/tutorials-list/tutorials-list')
require('./components/tutorials/tutorial/tutorial')
require('./components/ebs/ebs-item')
require('./components/ebs/ebs-list/ebs-list')

require('./components/refer-a-friend/refer-a-friend')
require('./components/employee-benefits/employee-benefits')
require('./components/support/support')
require('./components/blocker/blocker')

require('./components/releases/releases')
require('./components/feedback/feedback')
require('./components/pn-list/pn-list')

require('./components/single-select-popup/single-select-popup')
require('./components/nested-list-item/nested-list-item')
require('./components/pinch-zoom-image/pinch-zoom-image')
require('./components/camera-ux/camera-ux')

require('./components/pagination/pagination')
require('./components/sort/sort')

require('./components/salary-reports/sr-root/sr-root')
require('./components/salary-reports/sr-2fa/sr-2fa')
require('./components/salary-reports/sr-list/salary-reports-list')
require('./components/salary-reports/sr-list/salary-report-item/salary-report-item')
require('./components/salary-reports/sr-details/salary-report-details')

require('./components/vacation-request/vr-new/vr-new')
require('./components/vacation-request/vr-list/vr-list')
require('./components/vacation-request/vr-list/vr-list-item/vr-list-item')
require('./components/vacation-request/root/vr-root')

require('./components/mileage-money/mm-root/mm-root')
require('./components/mileage-money/mm-new/mm-new')
require('./components/mileage-money/mm-confirm/mm-confirm')
require('./components/mileage-money/mm-list/mm-list')
require('./components/mileage-money/mm-list/mm-list-item/mm-list-item')
require('./components/mileage-money/mm-submitted/mm-submitted')

// services:js
require('./services/settings')
require('./services/modal_window')
require('./services/validate')
require('./services/popup-register')
require('./services/database')
require('./services/vacation-request')

// directives:js
require('./directives/ng-model')
require('./directives/main_header')
require('./directives/modal_window')
require('./directives/sidebar')
require('./directives/splash-loader')
require('./directives/draw_canvas')
require('./directives/swipe_pagination')
require('./directives/spinner')
require('./directives/signature-field')
require('./directives/imageonload')
require('./directives/imageonerror')

// pipes:js
require('./pipes/time-to-decimals')
require('./pipes/week-number')
require('./pipes/week-period')
require('./pipes/total-time')
